@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
body {
  margin: 0;
  font-family: "Poppins", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #191720;
  font-size: 16px !important;
  background-color: #f5f4f9 !important;
}

* {
  letter-spacing: -0.5px;
  text-decoration: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #6d47f2 !important;
}

h1 {
  margin: 0 0 27px !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 30px;
}
/* Title of  every items */
h3 {
  font-size: 16px !important;
  line-height: 24px;
  font-weight: 600 !important;
}
