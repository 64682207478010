.settings a {
  color: #191720 !important;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.settings .icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: #f5f4f9;
  border-radius: 10px;
}

/* personal info page */
.personal-info .no-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  margin-right: 16px;
  border: 1px dashed #6d47f2;
  border-radius: 14px;
  background-color: rgba(109, 71, 242, 0.08);
  overflow: hidden;
}

.personal-info .no-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.personal-info b {
  color: #6d47f2;
}

/* Privacy and policy component styles ================================ */

.privacy p::first-line {
  margin-left: 50px !important;
}
