.user-name p {
  font-size: 14px !important;
  line-height: 21px;
  margin: 4px 0;
}

.navbar {
  margin-bottom: 40px;
}

.inner-header {
  background-color: #f5f4f9;
  height: 84px;
  display: flex;
  align-items: center;
}
.navbar .logout {
  background-color: #6d47f2;
  color: #f5f4f9 !important;
  border-radius: 12px;
  padding: 7px 10px;
  border: none;
}

.navbar-nav a {
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  margin-left: 20px;
  transition: 0.3s ease;
}

.navbar-nav a.active {
  color: #6d47f2 !important;
}

.navbar-nav a.active path {
  stroke: #6d47f2 !important;
}

.navbar-nav svg {
  position: relative;
  top: -2px;
}

.user-name h3 {
  font-size: 16px !important;
  line-height: 21px;
  font-weight: 600;
}

.inner-header > div {
  cursor: pointer;
  /* margin-bottom: 40px; */
}
