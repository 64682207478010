.navbar a {
  color: #7a7a7a !important;
}

.nav-tabs {
  border-bottom: none;
  background-color: #fff;
  border-radius: 16px;
  padding: 3px 3px 5px !important;
}

.nav-tabs .nav-link {
  height: 51px;
  border-radius: 16px !important;
  border: none !important;
  font-weight: 500;
  color: #191720;
}

.nav-tabs .nav-link:hover {
  border: none !important;
}

.nav-tabs .nav-link.active {
  background-color: #f5f2fe !important;
  color: #6d47f2;
  border: none !important;
}

/* user item */

.user-item h4 {
  font-size: 16px;
}
.user-item p {
  color: #7a7a7a;
}
