.tariffs-item label,
.total-pay {
  padding: 30px;
  width: 100%;
  display: inline-block;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 24px;
  transition: all 0.3s;
  position: relative;
  margin-top: 44px;
}

.tariffs-item .tariffs-header {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f5f4f9;
  border-radius: 12px;
  width: 50%;
  text-align: center;
  height: 50px;
  line-height: 24px;
  font-weight: 600;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7a7a7a;
}

.tariffs-item img {
  width: 38px;
}

.tariffs-item .pay-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
}

.tariffs-item .total-text {
  font-size: 18px;
}

.tariffs-item .total-money {
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
}

.tariffs-item .pay-circle {
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #7A7A7A;
  transition: all 0.3s ease;
}

.tariffs-item input:checked+label .pay-circle {

  border: 10px solid #6d47f2;
}

.tariffs-item input:checked+label {
  border-color: #6d47f2;
}

.tariffs-item input:checked+label .tariffs-header {
  background-color: #6d47f2;
  color: #fff;
}

.tariffs-item .tariffs-title {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  margin-top: 23px;
}

.tariffs-item .tariffs-title strong {
  font-size: 28px;
}

.tariffs-item ul li {
  display: flex;
  list-style: none;
  margin-bottom: 10px;
  align-items: center;
}