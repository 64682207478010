/* Form box style */
.form-container>div {
  min-height: 100vh;
}

.form-box {
  background-color: #ffffff;
  border-radius: 24px;
  padding: 50px;
}

.form-box .title {
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: -0.5px;
}

.form-box .title-bottom {
  font-size: 24px;
  line-height: 150%;
}

.form-box .help-msg {
  color: #7a7a7a;
  text-align: center;
  margin: 0;
}

.form-box .help-msg a {
  color: #191720;
  text-align: center;
}

.get-code-button {
  background-color: transparent;
  border: none;
  color: #6d47f2;
}

/* remove array from input type number  */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.form-box input[type="number"] {
  -moz-appearance: textfield;
}

.form-box input[maxlength="1"] {
  text-align: center;
}

.form-box input[maxlength="1"]:focus,
.form-box .focus-active {
  border-color: #6d47f2;
}

.form-box .error-box {
  border-color: red;
}

/* Button sytles */

.button {
  width: 100%;
  color: #fff;
  background: #6d47f2;
  border-radius: 16px;
  height: 60px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border: none;
  outline: none;
}

.cancel-btn {
  background-color: #EA5455;
}

button.disabled,
a.disabled {
  background-color: #7a7a7a !important;
}

/* Main Protected route style */
.main {
  width: 50%;
}

@media screen and (max-width: 992px) {
  .main {
    width: 100%;
  }
}

/* User imaga general style */
.user-image {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  overflow: hidden;
  background: rgb(109, 71, 242);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.2em;
}

.user-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bor-r16 {
  border-radius: 16px !important;
  background-color: #fff;
  padding: 14px;
  /* border: 1px solid #7a7a7a; */
}

/* Gray text class */
.text-gray {
  color: #7a7a7a !important;
}

.inner-body {
  background-color: #fff !important;
  min-height: calc(100vh - 84px);
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

/* Curson pointer */
.cursor-pointer {
  cursor: pointer;
}