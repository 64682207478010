.status-box {
  border-radius: 6px;
  padding: 3px 8px;
}

.success {
  background-color: #59c26426;
  color: #59c264;
}

.waiting {
  background-color: #f5f2fe;
  color: #6d47f2;
}
