.input-feild {
  border: 1px solid #e0e0e0;
  font-family: inherit;
  border-radius: 16px;
  height: 60px;
  width: 100%;
  outline: none;
  font-size: 16px;
  line-height: 24px;
  color: inherit;
  padding: 10px 15px;
  margin-bottom: 25px;
  position: relative;
}

textarea.input-feild {
  height: calc(1rem + 112px);
}

.input-feild input:focus-visible {
  border: none;
  outline: none !important;
  outline: -webkit-focus-ring-color auto 0px !important;
  background-color: #7a7a7a;
}

 /* input:focus{
  background-color: red !important;
  border-radius: 0;
} */

button.input-feild{
  background-color: transparent;
  text-align: left;
}

label.input-feild {
  display: flex;
  align-items: center;
  color: #7a7a7a;
}

label.input-feild span {
  margin-left: 15px;
  line-height: 25px;
}

.input-fields {
  position: relative;
}

.error-text {
  position: absolute;
  display: block;
  bottom: 3px;
  font-size: 14px;
}

.select-error-text {
  position: absolute;
  display: block;
  bottom: -20px;
  font-size: 14px;
}

.eye {
  position: absolute;
  right: 20px;
  top: 16px;
}

.custom-select .css-13cymwt-control,
.custom-select .css-t3ipsp-control {
  padding: 0 16px;
  border-radius: 16px;
  border: 1px solid #e0e0e0;
  height: 60px;
  margin-bottom: 25px;
  box-shadow: none;
}

.custom-select .css-1u9des2-indicatorSeparator {
  display: none !important;
}

/* .custom-select .css-13cymwt-control, */
.custom-select .css-t3ipsp-control:hover {
  border-color: #e0e0e0;
}

.rmdp-container {
  display: block !important;
}

